<template>
  <div :class="`wrapper d-flex align-items-center ${props.isDisabled ? '' : 'active'}`">
    <svg :class="`flag fi ${imagePath ? '' : `fi-${props.value.toLowerCase()}`} ${props.isDisabled ? 'flag-disabled' : ''}`" :data-src="imagePath" />
  </div>
</template>

<script setup>
import { computed } from 'vue';

import 'flag-icons/css/flag-icons.min.css';
import { currencyList } from '@/common/data';

const props = defineProps({
  value: {
    type: String,
    default: '',
  },
  currency: {
    type: String,
    default: '',
  },
  size: {
    type: String,
    default: '16px',
  },
  isDisabled: {
    type: Boolean,
    default: false,
  },
});

const imagePath = computed(() => {
  const country = props.currency
    ? currencyList.find((curr) => curr.short === props.currency)
    : currencyList.find((curr) => curr.countryCode === props.value.toLowerCase());

  return country?.icon;
});

const shadowSize = computed(() => `${props.size.replace('px', '') * 0.07}px`);
</script>

<style scoped lang="scss">
.wrapper {
  flex-shrink: 1;
  width: v-bind('size');
  aspect-ratio: 16 / 12;
  overflow: hidden;
  border-radius: 10%;
  position: relative;

  &.active:after {
    content: '';
    width: v-bind('size');
    height: 100%;
    aspect-ratio: 16 / 12;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 10%;
    box-shadow: inset 0 0 0 v-bind('shadowSize') var(--color-black-01);
  }

  .flag {
    width: v-bind('size');
    aspect-ratio: 16 / 12;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    @include transition-base('all');
  }

  .flag-disabled {
    opacity: 0.6;
    filter: grayscale(1);
  }
}
</style>
